const site = 'betatest123.com'
const sitekeys = {
    'betatest123.com': '6Lf2cUIhAAAAAHVBDaJvBkguUWQCx5Vfpt8sXd-c'
};

export const Root =
    process.env.NODE_ENV === 'development'
        ? {
            adminWebsiteUrl: `https://admin.betatest123.com`,
            serverUrl: `https://betatest123.com`,
            sportUrl: `https://sport.betatest123.com`,
            token: `player-betatest123.com-token`,

            // adminWebsiteUrl: 'http://localhost:2000',
            // serverUrl: 'http://localhost:2002',
            // sportUrl: 'http://localhost:2004',
            // token: '8866-player-token',

            site,
            siteKey: sitekeys[site],
            soliticsBrand: '5e01ce47-6540-4a23-b191-5ad0d354e7a3',
            soliticsPopupToken: '5e01ce47-6540-4a23-b191-5ad0d354e7a3',
            soliticsBranch: 'slotmaniax',
            socket: null
        }
        : {
            adminWebsiteUrl: `https://admin.betatest123.com`,
            serverUrl: `https://betatest123.com`,
            sportUrl: `https://sport.betatest123.com`,
            token: `player-betatest123.com-token`,
            site,
            siteKey: sitekeys[site],
            soliticsBrand: '5e01ce47-6540-4a23-b191-5ad0d354e7a3',
            soliticsPopupToken: '5e01ce47-6540-4a23-b191-5ad0d354e7a3',
            soliticsBranch: 'slotmaniax',
            socket: null
        };
